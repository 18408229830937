<template>
  <v-tooltip top z-index="100" max-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="primary" dark v-bind="attrs" v-on="on">
        {{ icon }}
      </v-icon>
    </template>
    <p class="mb-0">{{ text }}</p>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
};
</script>
